@import '../../scss/theme';

.copied {
    color: var(--copy-to-clipboard-notification-color);
    font-weight: var(--copy-to-clipboard-notification-weight);
    font-size: var(--copy-to-clipboard-notification-font-size);
}

.wrapper {
    display: inline-block;
    min-width: 60px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
