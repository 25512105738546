@import 'common/scss/variables';

.form {
    color: $text-muted;

    :global {
        .form-error {
            display: none;
        }

        .col-form-label {
            padding-top: 0;
            padding-bottom: 0;
        }

        .form-group {
            align-items: center;
            margin-bottom: 0;
        }

        .accordion-toggle-hover {
            cursor: pointer;
        }

        .accordion-toggle-hover:hover {
            color: $blue;
        }
    }

    input::placeholder {
        color: $grey !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.whiteText {
    color: white;
}

label {
    font-weight: 700;
    margin-bottom: 0;
}

.label {
    color: $input-label-color;
}

// Buttons
.btn {
    border-width: 2px;
}

.btn-primary {
    &:hover {
        background-color: #003f64;
        border-color: #003f64;
    }

    &:disabled {
        background-color: $grey;
        border-color: $grey;
    }
}

.btn-dark {
    color: #fff;
    background-color: #003f64;
    border-color: #003f64;

    &:hover {
        color: #fff;
        background-color: #00264b;
        border-color: #00264b;
    }
}

.btn-invert {
    color: $primary;
    border-color: $primary;

    &:hover {
        color: $primary;
    }
}

.btn-status {
    padding-top: 2px;
    padding-bottom: 2px;

    &.active {
        color: #fff;
        background-color: $primary;
    }
}

.text-link {
    color: $primary;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        color: #003f64;
        text-decoration: none;
    }
}
