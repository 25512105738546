@import '../../../scss/theme';

.headline {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--headline-h3-color);
    font-size: var(--headline-h3-size);
    margin-bottom: 1rem;
}
