@import 'common/scss/variables';

.accordionHeader {
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 0.75rem 1.25rem;

    h2 {
        margin: 0;
        margin-bottom: 1em;
        padding: 0;
    }

    .subline {
        font-size: 0.6em;
    }

    svg {
        color: $text-default;
    }
}

.toggleIconActive {
    display: inline-block;
    transition: transform 0.3s;
}

.toggleIcon {
    display: inline-block;
    transform: rotate(180deg);
    transition: transform 0.3s;
}
