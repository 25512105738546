@import 'common/scss/theme';

.container {
    position: relative;
    flex: 1;

    :global {
        .invalid-feedback {
            display: none !important;
        }
    }
}

.errorMessage {
    bottom: 0;
    left: 0;
    color: var(--validation-error-color);
    font-size: var(--validation-error-size);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}
