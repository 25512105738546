@import 'variables';

:root {
    // h1
    --headline-h1-size: #{$h1-font-size};
    --headline-h1-color: #{$blue};

    // h2
    --headline-h2-color: #{$text-default};
    --headline-h2-size: #{$h2-font-size};

    // text-muted
    --text-muted-color: #{$text-muted};
    --text-muted-weight: 400;
}
