@import "common/scss/variables";

.navbar {
    padding: 0 !important;
    padding-bottom: 0.3rem !important;
    overflow: auto;
}

.navbarText {
    vertical-align: middle;
    padding-right: 1rem;
    font-weight: 600;
}

.navbarLink {
    text-decoration: none;
    padding-left: 0 !important;
    padding-right: 1rem !important;
    font-weight: 400;

    &::before {
        content: inherit;
    }
}

.mb4 {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.cardWrapper {
    padding: 20px 0 !important;
}

.cardAccordion {
    border-color: 1px solid $border-color !important;
}
