@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// Pallet
//
$white: #fff;
$black: #000;
$red: #c43333;
$grey: #dcdcdc;
$light-grey: #999;
$light: #f9f9f9;
$blue: #01629d;

$light-blue: #e6f0fa;
$light-blue-hover: #f7faff;

$primary: $blue;
$secondary: $red;
$secondary-hover: #962626;

$body-background-color: $white;
$box-shadow-color: $grey;

$border-color: rgba($black, 0.25);

// Font Family
//
$font-family-base: 'Sarabun', sans-serif;

//Typography
//
$text-default: #444;
$text-muted: #666;

$headline-h1-color: $blue;

$font-size-base: 1rem;
$h1-font-size: 2.2rem;
$h2-font-size: 1.5rem;

$headings-font-weight: 700;

$small-font-size: 0.75rem;

// Links
//
$link-color: $blue;
$link-hover-color: $blue;

// Buttons
//
$btn-font-weight: 700;
$btn-padding-y: 7px;
$btn-padding-x: 25px;
$btn-border-radius: 20px;

$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;

// Inputs
//
$input-label-color: rgba($black, 0.25);
$input-border-radius: 0;

$custom-checkbox-indicator-border-radius: 0;

$input-focus-box-shadow: none;

// Tooltip
//
$tooltip-opacity: 1;
