.menu-link {
    position: relative;
    color: $link-color !important;
    font-size: 1.3rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s linear;

    &::before {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        opacity: 0;
        bottom: 0;
        background: $link-color;
        transition: all 0.2s linear;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
}
