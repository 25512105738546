@import "common/scss/variables";

.table {
    @media only screen and (min-device-width: 1024px) {
        table-layout: fixed;
    }

    th[align=center] {
        text-align: center;
    }

    th,
    td {
        vertical-align: middle !important;
    }

    tr:nth-child(odd) {
        background: $light-blue;
    }

    tr:hover {
        background: $light-blue-hover;
    }
}

.p0 {
    padding: 0 !important;
}

.badge {
    font-size: 14px;
}

.subtitle {
    font-size: 16px;
}
