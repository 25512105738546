@import 'common/scss/variables';
@import 'common/scss/extends';

.pageFooter {
    border-top: 1px solid $border-color;
    color: $light-grey;
    padding: 3rem 0 0;

    a {
        @extend .menu-link;

        color: $light-grey !important;
        font-weight: 400;

        &::before {
            background: $light-grey;
        }
    }

    .socialLink {
        &::before {
            content: inherit;
        }

        img {
            max-width: 40px;
            width: 100%;
        }
    }
}

.img {
    max-width: 245px;
    width: 100%;
    padding: 6rem 0 4rem;
}

.copyright {
    color: $light-grey;
    text-align: left;
    line-height: 3;
}
