@import "common/scss/variables";

.cardImg {
    width: 100%;
    z-index: 1000;
    margin-bottom: 30px;

    @media only screen and(max-device-width: 1023px) {
        margin-bottom: 0;
    }
}

.cardFullScreen {
    @media only screen and(max-device-width: 1023px) {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media only screen and(max-device-width: 1023px) and (orientation: landscape) {
        width: auto;
        height: 100%;
    }
}

.cardFullScreenBg {
    @media only screen and(max-device-width: 1023px) {
        background: #000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.2s linear;
        z-index: -1;
    }
}

.show {
    opacity: 0.9;
    z-index: 999;
}

.closeIcon {
    display: none;

    @media only screen and(max-device-width: 1023px) {
        height: 40px;
        width: 40px;
        position: absolute;
        right: 10px;
        top: 10px;
        stroke: $red;
        display: block;
    }
}

.cardText {
    display: none;

    @media only screen and(max-device-width: 1023px) {
        text-align: center;
        text-transform: uppercase;
        color: $red;
        font-weight: 600;
        padding: 15px 0;
        display: block;
    }
}

.cardTextFullScreen {
    opacity: 0;
}
