@import 'variables';

a {
    position: relative;
    font-weight: 600;
    text-decoration: none;

    &::before {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        opacity: 0;
        bottom: -1px;
        background: $link-hover-color;
        transition: all 0.2s linear;
    }

    &:hover {
        color: $link-hover-color;
        text-decoration: none;

        &::before {
            opacity: 0.3;
        }
    }
}

ul,
dl,
p {
    margin-top: 1em;
}

p {
    margin-bottom: 1.9rem;
}

div#search {
    margin-right: 0;
}

#search input#searchField {
    height: 30px;
    float: left;
}

#content {
    padding-left: 0;
    font-size: $font-size-base;
}

.radioGroupWrapper {
    .form-group {
        display: flex;
        align-items: center;

        .form-check {
            margin-right: 1rem;
        }
    }
}
