@import 'common/scss/variables';

.button {
    color: $secondary !important;

    &:hover {
        color: $secondary-hover !important;
    }
}

.column {
    &:hover {
        background: $light-blue-hover;
    }
}
