@import 'common/scss/variables';

.pageHeader {
    background: $white;
    text-align: center;
    box-shadow: 0 0 10px $box-shadow-color;
    border-bottom: 1px solid $grey;
}

.logo {
    position: relative;
    max-width: 72px;
    margin: 1.5rem 0 0;
}

.navbar {
    justify-content: center;

    a {
        position: relative;
        color: $text-default !important;
        font-size: 1.4rem;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.2s linear;

        &::before {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            opacity: 0;
            bottom: 0;
            background: $text-default;
            transition: all 0.2s linear;
        }

        &:hover {
            &::before {
                opacity: 0.3;
            }
        }
    }
}
