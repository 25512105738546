@import 'common/scss/variables';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap';
@import 'common/scss/ntsv-bootstrap.scss';

body {
    margin: 0;
    font-family: $font-family-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $body-background-color;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
