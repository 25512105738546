@import "common/scss/variables";

.h1 {
    margin: 0;
    color: $headline-h1-color;
    font-size: $h1-font-size;
    font-weight: 500;
    padding-bottom: 2rem;
}

.notUnderlined {
    position: absolute;
    top: -180px;
    right: 0;
    text-decoration: none;
    cursor: pointer;
    display: block;
}
