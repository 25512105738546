@import 'common/scss/variables';

.box {
    background: $light-blue;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
}

.card {
    background: $light-blue !important;
}

.cardBody {
    padding: 1rem;
}

.ddlRadioBox {
    background: $light-blue;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 1rem;
}

.list {
    padding-bottom: 1rem;

    &:last-child {
        padding-bottom: 0;
    }
}

.error {
    color: $red;
    font-weight: 600;
}
