@import 'common/scss/variables';

.label {
    font-size: $font-size-base;
}

.alignStart {
    align-items: start !important;
}

.alignEnd {
    align-items: flex-end !important;
}

.alignBaseline {
    align-items: baseline !important;
}

.inputWithInfo {
    display: flex;
    align-items: baseline;
}
